import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { StyledContainer } from "../containers/index"

const StyledCertificatesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin: 24px 0;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    grid-template-columns: 1fr;
  }
`

const CertificatesGrid = ({ certificates }) => {
  return (
    <StyledContainer>
      <StyledCertificatesGrid>
        {certificates.map((image, index) => {
          return (
            <div
              key={index}
              style={{ boxShadow: "0px 8px 20px rgba(0,0,0,.1)" }}
            >
              <Img fluid={image.childImageSharp.fluid} />
            </div>
          )
        })}
      </StyledCertificatesGrid>
    </StyledContainer>
  )
}

export default CertificatesGrid
